<template>
  <div class="container-fluid">
    <div class="calendar-app">
      <FullCalendar
        id="calendar"
        class="calendar-app-calendar calendar"
        ref="fullCalendar"
        defaultView="dayGridMonth"
        :header="{
          left: 'prev, next today myCustomButton',
          center: 'title',
          right: 'dayGridMonth, timeGridWeek, timeGridDay, listWeek'
        }"
        :plugins="calendarPlugins"
        :events="calendarEvents"
        :editable="true"
        @dateClick="handleDateClick"
        @eventClick="handleEventClick"
        locale="vi"
        :allDaySlot="false"
      />
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  components: {
    FullCalendar
  },
  data: function() {
    return {
      calendarPlugins: [
        // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin // needed for dateClick
      ],
      calendarWeekends: true,
      calendarEvents: [
        // initial event data
        {
          id: 1,
          title: "Tham gia sự kiện",
          start: new Date(),
          textColor: "#ffffff"
        }
      ]
    };
  },
  methods: {
    /**
     * Handle event click
     */
    handleEventClick: function(arg) {
      let calendar = this.$refs.fullCalendar.getApi();
      const updatedEvent = calendar.getEventById(arg.event.id);
      updatedEvent.setProp("title", "New Title");
    },
    /**
     * Toggle weekends
     */
    toggleWeekends() {
      this.calendarWeekends = !this.calendarWeekends; // update a property
    },
    /**
     * Go to past
     */
    gotoPast() {
      let calendarApi = this.$refs.fullCalendar.getApi(); // from the ref="..."
      calendarApi.gotoDate("2020-03-27"); // call a method on the Calendar object
    },
    /**
     * Handle date click
     */
    handleDateClick(arg) {
      if (confirm("Would you like to add an event to " + arg.dateStr + " ?")) {
        this.calendarEvents.push({
          id: this.calendarEvents.length + 1,
          title: "Tham gia chống dịch",
          start: arg.date,
          textColor: "#ffffff"
        });
      }
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import '../../style/color.scss';
.calendar-app {
  padding: 5px 0px 15px 0px;
  font-size: 14px;
}
.calendar-app-top {
  margin: 0 0 3em;
}
.calendar-app-calendar {
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;
}
/deep/ .fc-button-primary {
  background-color: $primary !important;
  border: none;
}
</style>
